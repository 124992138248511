import axios from 'axios'

export default {
  alert ({ commit, state }, message) {
    setTimeout(() => {
      commit('setAlert', message)
    }, 100)
    setTimeout(() => {
      commit('setAlert', false)
    }, 8000)
  },
  initPlugin ({ commit, state, dispatch }, farmId) {
    commit('setStateProperty', { property: 'spinner', value: true })
    const timeStamp = new Date().getTime()
    const url = 'https://api.efficientvineyard.com/farm/' + farmId + '?timestamp=' + timeStamp
    axios.get(url).then(response => {
      response.data.id = farmId
      commit('setStateProperty', { property: 'farm', value: response.data })
      commit('setStateProperty', { property: 'spinner', value: false })
      dispatch('initVizMap')
      return true
    })
  },
  initVizMap ({ commit, state, dispatch }) {
    mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ' // eslint-disable-line
    state.mapObject = new mapboxgl.Map({ // eslint-disable-line
      container: 'vizMap',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      style: 'https://api.maptiler.com/maps/da520b6d-739a-4646-888f-1d3f2b664f78/style.json?key=IuJ0D0R9S0Cjl4krhvr1',
      center: [-95, 40],
      zoom: 3
    })

    state.mapObject.addControl(new mapboxgl.NavigationControl()) // eslint-disable-line
    // WHEN MAP LOADS SETUP BLOCKS
    state.mapObject.on('load', function () {
      // VARS
      const bounds = new mapboxgl.LngLatBounds() // eslint-disable-line
      // Get blocks geoJson File
      const blocksGeoJsonUrl = state.farm.blocksGeoJsonURL
      axios.get(blocksGeoJsonUrl).then(response => {
        state.blocksGeoJson = response.data
        // ADD LAYERS
        state.mapObject.addLayer({
          id: 'blocksLayer',
          type: 'fill',
          source: {
            type: 'geojson',
            data: state.blocksGeoJson
          },
          layout: {},
          paint: {
            'fill-color': '#6e59c7', // eslint-disable-line
            'fill-opacity': 0.8, // eslint-disable-line
          }
        })
        state.mapObject.addLayer({
          id: 'blocksLayerOutline',
          type: 'line',
          source: {
            type: 'geojson',
            data: state.blocksGeoJson
          },
          layout: {},
          paint: {
            'line-width': 2,
            'line-color': '#eee',
            'line-opacity': 1
          }
        })
        //
        //
        // CLICK EVENTS
        state.mapObject.on('click', 'blocksLayer', function (e) {
          // if (!state.dataset.id) {
          const currentBlocks = state.selectedBlocks
          // Remove highlighted block layers
          currentBlocks.forEach((block, i) => {
            if (state.mapObject.getLayer(block.properties.blockId)) {
              state.mapObject.removeLayer(block.properties.blockId)
              state.mapObject.removeSource(block.properties.blockId)
            }
          })
          // Select clicked block if not already selected
          let wasSelected = false
          for (let i = currentBlocks.length - 1; i >= 0; i--) {
            if (currentBlocks[i].properties.blockId === e.features[0].properties.blockId) {
              currentBlocks.splice(i, 1)
              wasSelected = true
            }
          }
          if (!wasSelected) {
            currentBlocks.push(e.features[0])
          }
          currentBlocks.forEach((block, i) => {
            state.mapObject.addLayer({
              id: block.properties.blockId,
              type: 'fill',
              source: {
                type: 'geojson',
                data: block
              },
              layout: {},
              paint: {
                'fill-color': '#ffc107', // eslint-disable-line
                'fill-opacity': 0.8, // eslint-disable-line
              }
            })
          })
          commit('setStateProperty', { property: 'selectedBlocks', value: currentBlocks })
          // }
        })
        // MANUALLY ADDING POINTS
        state.mapObject.on('click', 'datasetPreview', function (e) {
          if (state.datasetModel === 'manual') {
            const clickedPoint = e.features[0]
            let removePoint = false
            const grid = {
              type: 'FeatureCollection',
              features: []
            }
            if (state.gridGeoJson.features) {
              for (let i = state.gridGeoJson.features.length - 1; i >= 0; i--) {
                if (clickedPoint.geometry.coordinates[0] === state.gridGeoJson.features[i].geometry.coordinates[0] && clickedPoint.geometry.coordinates[1] === state.gridGeoJson.features[i].geometry.coordinates[1]) {
                  console.log('Removed Point')
                  removePoint = true
                } else {
                  grid.features.push({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: state.gridGeoJson.features[i].geometry.coordinates
                    }
                  })
                }
              }
            }
            if (!removePoint) {
              grid.features.push({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: clickedPoint.geometry.coordinates
                }
              })
              commit('setStateProperty', { property: 'gridGeoJson', value: grid })
              dispatch('mapGrid')
            }
          }
        })
        // REMOVE POINTS
        state.mapObject.on('click', 'gridOutline', function (e) {
          if (state.datasetModel === 'manual') {
            const clickedPoint = e.features[0]
            // let removePoint = false
            const grid = {
              type: 'FeatureCollection',
              features: []
            }
            if (state.gridGeoJson.features) {
              for (let i = state.gridGeoJson.features.length - 1; i >= 0; i--) {
                if (clickedPoint.geometry.coordinates[0] === state.gridGeoJson.features[i].geometry.coordinates[0] && clickedPoint.geometry.coordinates[1] === state.gridGeoJson.features[i].geometry.coordinates[1]) {
                  console.log('Removed Point')
                } else {
                  grid.features.push({
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Point',
                      coordinates: state.gridGeoJson.features[i].geometry.coordinates
                    }
                  })
                }
              }
            }
            commit('setStateProperty', { property: 'gridGeoJson', value: grid })
            dispatch('mapGrid')
          }
        })
        // END CLICK EVENTS
        //
        //
        // MOUSE ENTER AND LEAVE POINTERS
        state.mapObject.on('mouseenter', 'blocksLayer', function () {
          state.mapObject.getCanvas().style.cursor = 'pointer'
        })
        state.mapObject.on('mouseleave', 'blocksLayer', function () {
          state.mapObject.getCanvas().style.cursor = ''
        })
        // BOUNDS UPDATE ONLY IF THERE ARE FEATURES
        if (state.blocksGeoJson.features.length > 0) {
          state.blocksGeoJson.features.forEach(feature => {
            feature.geometry.coordinates[0].forEach(coordinatePair => {
              bounds.extend(coordinatePair)
            })
          })
          state.mapObject.fitBounds(bounds, {
            padding: 50,
            animate: false
          })
        } else {
          const lat = state.farm.geolocation.latitude
          const lng = state.farm.geolocation.longitude
          state.mapObject.flyTo({
            center: [lng, lat],
            zoom: 15,
            animate: false
          })
        }
      }) // axios get blocksGeoJson
    }) // map.on load
  }
}
