export default {
  setStateProperty (state, options) {
    state[options.property] = options.value
  },
  setLimit (state, limit) {
    state.limit = limit
  },
  setLoadingMore (state, boolean) {
    state.setLoadingMore = boolean
  },
  setSpinner (state, boolean) {
    state.spinner = boolean
  },
  setDatasets (state, datasets) {
    state.datasets = datasets
  },
  setDataset (state, dataset) {
    state.dataset = dataset
  },
  setDownload (state, dataset) {
    state.download = dataset
  },
  showOptions (state, boolean) {
    state.showOptions = boolean
  },
  showDownload (state, boolean) {
    state.showDownload = boolean
  },
  setAlert (state, message) {
    state.alert = message
  }
}
