<template>
  <div id="options" class="container pt-4">
    <div v-if="!$store.state.showDownload">
      <!-- <b-btn
        @click="backToDatasets()"
        class="btn-sm mb-2"
      >← Back to datasets</b-btn> -->
      <p class="mb-1"><strong>Choose a Dataset</strong></p>
      <DatasetPicker v-if="$store.state.farm.id && !$store.state.dataset.name" />
      <!-- SELECTED DATASET -->
      <div
        v-if="$store.state.dataset.name"
        class="list-group-item list-group-item-action mb-5"
        style="background-color:lightgreen;"
      >
        <p class="lead mb-1">{{ $store.state.dataset.name }}</p>
        <i
          class="fas fa-times-circle clickable"
          id="remove-dataset"
          @click="removeDataset()"
        ></i>
        <!-- LOADING DATASET SPINNER -->
        <!-- <div
          class="loading-dataset-spinner"
        >
          <RingLoader
            :color="'#61539b'"
            id="spinner"
          ></RingLoader>
        </div> -->
      </div>

      <div class="mb-5">
        <p class="mb-1"><strong>Selected Blocks</strong> ({{ $store.state.selectedBlocks.length }})</p>
        <p v-if="$store.state.selectedBlocks.length < 1" class="text-danger"><small>OPTIONAL: Click blocks on the map to select. This will help our interpolation process and is especially important for datasets with few points.</small></p>
        <!-- <a
          class="btn btn-primary btn-sm"
          @click="selectAllBlocks()"
        >Select all Blocks</a> -->
        <ul id="blocksList">
          <li
            v-for="(block, i) in $store.state.selectedBlocks"
            v-bind:key="i"
          >{{ block.properties.name }}</li>
        </ul>
      </div>

      <p class="mb-1"><strong>Interpolate {{ $store.state.dataset.name }}</strong></p>
      <p>A new interpolated dataset will be created using a 3 meter grid. Note that any filters applied to the dataset will be active during this process.</p>
      <p
        v-if="$store.state.dataset.headers"
        class="mb-0 mt-4"
      ><strong>Choose value to interpolate by</strong></p>
      <div
        v-for="(header, index) in $store.state.dataset.headers"
        v-bind:key="index"
        class="btn btn-sm btn-primary mt-1 mr-1 clickable"
        @click="chosenHeader = header"
        :class="{'active':(chosenHeader === header)}"
      >
        {{ header }}
      </div>
      <!-- <div>
        <b-btn class="mt-3 btn-sm" v-b-toggle.advancedOptions>Advanced Options ></b-btn>
        <b-collapse id="advancedOptions" class="mt-2">
          <b-card>
            <p class="card-text"><strong>Advanced Options</strong></p>
            <b-form-group id="input-group-neighborhoodSetting" label="Neighborhood Based On:" label-for="neighborhoodSetting">
              <b-form-select
                id="neighborhoodSetting"
                v-model="neighborhoodSetting"
                :options="[{ text: 'Fixed Distance', value: 'distance' }, { text: 'Number of Nearest Neighbors', value: 'nearest' }]"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-distance"
              label="Neighborhood Distance in Meters:"
              label-for="distance"
              v-if="neighborhoodSetting === 'distance'"
            >
              <b-form-select
                id="distance"
                v-model="neighborhoodDistance"
                :options="[{ text: '8 Meters', value: 0.008 }, { text: '16 Meters', value: 0.016 }]"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-nearest"
              label="Number of Nearest Neighbor Points:"
              label-for="nearest"
              v-if="neighborhoodSetting === 'nearest'"
            >
              <b-form-select
                id="nearest"
                v-model="neighborhoodMaxPoints"
                :options="[{ text: '20 Neighbors', value: 20 }, { text: '50 Neighbors', value: 50 }, { text: '100 Neighbors', value: 100 }]"
              ></b-form-select>
            </b-form-group>
          </b-card>
        </b-collapse>
      </div> -->
      <b-btn
        v-if="chosenHeader"
        class="w-100 mt-4 btn-lg btn-success"
        @click="interpolate()"
      >Interpolate</b-btn>
    </div>
    <Download v-if="$store.state.showDownload" />
  </div><!-- /#options -->
</template>

<script>
import Download from './Download'
import DatasetPicker from './DatasetPicker'
// import RingLoader from 'vue-spinner/src/GridLoader'
const fb = require('../firebaseConfig.js')

export default {
  data () {
    return {
      chosenHeader: false,
      neighborhoodSetting: 'distance',
      neighborhoodDistance: 0.016,
      neighborhoodMaxPoints: 20
    }
  },
  components: {
    Download,
    DatasetPicker
    // RingLoader
  },
  methods: {
    removeDataset () {
      this.$store.commit('setStateProperty', { property: 'dataset', value: {} })
      const mapObject = this.$store.state.mapObject
      if (mapObject.getLayer('datasetPreview')) {
        mapObject.removeLayer('datasetPreview')
        mapObject.removeSource('datasetPreview')
      }
    },
    // backToDatasets () {
    //   this.$store.commit('setSpinner', false)
    //   this.$store.commit('showOptions', false)
    // },
    interpolate () {
      const dataset = this.$store.state.dataset
      dataset.createdOn = new Date()
      dataset.interpolated = false
      dataset.chosenHeader = this.chosenHeader
      // dataset.neighborhoodSetting = this.neighborhoodSetting
      // dataset.neighborhoodDistance = this.neighborhoodDistance
      // dataset.neighborhoodMaxPoints = this.neighborhoodMaxPoints

      if (this.$store.state.selectedBlocks.length > 0) {
        dataset.selectedBlocks = this.$store.state.selectedBlocks.map(block => ({
          geometry: JSON.stringify(block.geometry),
          properties: {
            name: block.properties.name,
            blockId: block.properties.blockId
          }
        }))
      } else {
        dataset.selectedBlocks = []
      }

      fb.interpolationsCollection.add(dataset).then(doc => {
        this.$store.commit('showOptions', false)
        this.$store.commit('showDownload', true)
        fb.interpolationsCollection.doc(doc.id).onSnapshot(snapshot => {
          const download = snapshot.data()
          download.id = snapshot.id
          download.name = 'Interpolated_' + download.name
          this.$store.commit('setDownload', download)
          if (download.interpolated) {
            this.$store.commit('setSpinner', false)
          }
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
  #options {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    z-index: 0;
    overflow-y: scroll;
    padding-bottom: 50px;
  }

  #remove-dataset {
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
