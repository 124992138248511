<template>
  <div id="home">
    <div class="container mt-4">
      <!-- <DatasetPicker v-if="$store.state.farm.id" /> -->
      <Options />
      <VizMap />
      <!-- <Download v-if="$store.state.showDownload" /> -->
    </div><!-- /.container -->
  </div><!-- /#home -->
</template>

<script>
import Options from './Options'
// import DatasetPicker from './DatasetPicker'
// import Download from './Download'
import VizMap from './VizMap'
export default {
  components: {
    Options,
    VizMap
    // DatasetPicker,
    // Download
  },
  created () {
    this.$store.dispatch('initPlugin', this.$route.params.farmId)
  }
}
</script>
