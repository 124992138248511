<template>
  <div id="download" class="pb-5">
    <!-- <b-btn
      @click="backToDatasets()"
      class="btn-sm mb-2"
    >← Back to datasets</b-btn> -->
    <p
      class="lead text-center"
      id="download-message"
      v-if="!$store.state.download.interpolated"
    >Your dataset is cooking up. This could take a while so please hold tight!</p>
    <!-- <b-progress
      v-if="!$store.state.download.interpolated"
      :value="loadPercent"
      :max="100"
      animated
      class="mt-4"
      v-on:load="startLoadProgress()"
    ></b-progress> -->
    <h2
      v-if="download.interpolated"
    >Dataset ready!</h2>
    <!-- <b-btn
      v-if="$store.state.download.interpolated"
      @click="downloadDataset()"
      class="btn-lg btn-success mt-4 mr-1"
    >Download Dataset</b-btn> -->

    <b-form-group
      v-if="download.interpolated"
      label="New Dataset Name:"
    >
      <b-form-input v-model="download.name"></b-form-input>
    </b-form-group>

    <FolderPicker v-if="download.interpolated" />

    <b-btn
      v-if="download.interpolated && !posting"
      @click="post()"
      class="btn-lg mt-4 w-100"
      variant="success"
    >Add Dataset to myEV</b-btn>
    <b-btn
      v-if="posting"
      class="btn-lg mt-4 w-100"
    >Adding Dataset to myEV</b-btn>
  </div><!-- /#download -->
</template>

<script>
import FolderPicker from './FolderPicker'
import firebase from 'firebase'
import axios from 'axios'

export default {
  data () {
    return {
      loadPercent: 0,
      timeLeft: false,
      posting: false
    }
  },
  components: {
    FolderPicker
  },
  created () {
    this.startLoadProgress()
  },
  computed: {
    download: {
      get () {
        return this.$store.state.download
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'download', value: value })
      }
    }
  },
  methods: {
    backToDatasets () {
      this.$store.commit('showDownload', false)
      this.$store.commit('setSpinner', false)
    },
    post () {
      this.posting = true
      const postObject = {
        name: this.$store.state.download.name,
        createdBy: this.$store.state.download.createdBy,
        farmId: this.$store.state.download.farmId,
        folderId: this.$store.state.folder.id,
        vizSettings: {
          colorHeader: this.$store.state.download.chosenHeader,
          colorBy: 'valueBased'
        }
      }
      console.log(postObject)
      const storageRef = firebase.storage().ref(this.$store.state.download.downloadPath)
      storageRef.getDownloadURL().then(url => {
        postObject.url = url
        return axios.post('https://api.efficientvineyard.com/post/dataset', postObject)
      }).then(response => {
        if (response.data.success) {
          this.$store.dispatch('alert', 'This new dataset has been added to your myEV farm! You can now close this plugin window and return to myEV.')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    downloadDataset () {
      const storageRef = firebase.storage().ref(this.$store.state.download.downloadPath)
      const cleanDescription = this.$store.state.dataset.name.replace(/\s+/g, '_')
      const fileName = cleanDescription + '.geojson'
      storageRef.getDownloadURL().then(url => {
        return fetch(url)
      }).then(response => {
        return response.blob()
      }).then(blob => {
        return URL.createObjectURL(blob)
      }).then(object => {
        const a = document.createElement('a')
        a.href = object
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }).catch(err => {
        console.log(err)
      })
    },
    startLoadProgress () {
      this.$store.commit('setSpinner', true)
      // const self = this
      // function updateLoadPercent (time) {
      //   setTimeout(() => {
      //     if (self.loadPercent < 100) {
      //       self.loadPercent = self.loadPercent + 1
      //       self.timeLeft = self.timeLeft - time
      //       updateLoadPercent(time)
      //     }
      //   }, time)
      // }
      // updateLoadPercent(2000)
      // if (!this.$store.state.download.estimatedLoadTime) {
      //   setTimeout(function () {
      //     self.startLoadProgress()
      //   }, 10)
      // } else {
      //   const estimatedLoadTime = this.$store.state.download.estimatedLoadTime
      //   const loadIterationTime = estimatedLoadTime / 100
      //   self.timeLeft = estimatedLoadTime
      //   updateLoadPercent(loadIterationTime)
      // }
    }
  },
  filters: {
    readableTime (milliseconds) {
      var date = new Date(milliseconds)
      var m = date.getMinutes()
      var s = date.getSeconds()
      return m + ':' + s
    }
  }
}
</script>
