import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyB4-DWViIFi0YF8YUU-QhHp0VGAm35Br28',
  authDomain: 'lergp-225300.firebaseapp.com',
  databaseURL: 'https://lergp-225300.firebaseio.com',
  projectId: 'lergp-225300',
  storageBucket: 'lergp-225300.appspot.com',
  messagingSenderId: '826369939709',
  appId: '1:826369939709:web:15a3979540822e36808694',
  measurementId: 'G-4S85KPNQ9G'
}
firebase.initializeApp(config)

const db = firebase.firestore()
const interpolationsCollection = db.collection('interpolations')

export {
  db,
  interpolationsCollection
}
