<template>
  <div id="app" class="mb-5">
    <router-view/>
    <!-- SPINNER -->
    <div
      id="whole-spinner"
      v-if="$store.state.spinner"
    >
      <RingLoader
        :color="'#61539b'"
        id="spinner"
      ></RingLoader>
    </div>
    <transition name="fade">
      <Alert v-if="$store.state.alert" />
    </transition>
  </div>
</template>

<script>
import RingLoader from 'vue-spinner/src/GridLoader'
import Alert from './components/Alert'

export default {
  components: {
    RingLoader,
    Alert
  }
}
</script>

<style scoped>
#whole-spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

#spinner {
  z-index: 0;
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active {
  transition: opacity 0s;
}

.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
