export default {
  alert: false,
  breadcrumbs: [
    {
      id: 'root',
      name: 'Root'
    }
  ],
  datasets: [],
  dataset: {},
  datasetGeoJson: {},
  datasetHeader: false,
  download: {},
  farm: {},
  folders: [],
  folder: {
    id: 'root',
    name: 'Root'
  },
  loadingDataset: false,
  limit: 10,
  mapObject: {},
  selectedBlocks: [],
  setLoadingMore: false,
  spinner: false,
  showOptions: false,
  showDownload: false
}
